import { C2cCategoryPackageTypeEnum } from 'Pages/C2cOrderPayment/enums';
import {
  Location
} from 'Utilities/constants';
import { C2cProductModel } from 'Stores/c2c-order-payment';
export interface GoodsInfoModel {
	id: string;
	key: string;
	productId: number;
	categories: CategoryGoodsInfoModel;
	productName: string;
	material: string;
	quantity: string;
	price: string;
	receipts: string[];
	goodsUrl: string;
	name: string;
}

export interface ProductDeclarationByCategory {
	id: string
	productQuantity?: number
	productInfo?: C2cProductModel
	productName?: string
	productCode?: string
	productCategory?: string
}

export interface CategoryGoodsInfoModel {
	id: number;
	catId: number;
	product: string;
	code: string;
	imgUrl: string;
	price: number;
	type: string;
	status: string;
	langCode: string;
	isWarning: boolean;
	isLocal: boolean;
	description: string;
	createdAt: string;
	updatedAt: string;
	viewNo: number;
	trucking: boolean;
	isPremium: boolean;
	categoryCode: string;
	isKorean: boolean;
	categoryName: string;
	categoryImgUrl: string;
	categoryStatus: string;
	packageType: C2cCategoryPackageTypeEnum;
}
export interface GoodsInfoRawModel {
	id?: string;
	key: string;
	product_id: number;
	categories: CategoryGoodsInfoRawModel;
	product_name: string;
	material: string;
	quantity: string;
	price: string;
	receipts: string[];
	goods_url: '';
	name: string;
}

export interface CategoryGoodsInfoRawModel {
	id: number;
	cat_id: number;
	product: string;
	code: string;
	img_url: string;
	price: number;
	type: string;
	status: string;
	lang_code: string;
	is_warning: boolean;
	is_local: boolean;
	description: string;
	created_at: string;
	updated_at: string;
	view_no: number;
	trucking: boolean;
	is_premium: boolean;
	category_code: string;
	is_korean: boolean;
	category_name: string;
	category_img_url: string;
	category_status: string;
	package_type: C2cCategoryPackageTypeEnum;
}

export interface GoodProduceType {
  lang: string;
  location?: Location;
	locationFrom: number | string
	locationTo: number | string
}
