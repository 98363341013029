import './styles.scss';
import { useTranslation } from 'react-i18next';

interface PropsModel {
  className?: string;
  isActive?: boolean;
  label?: string;
  icon?: string;
  color?: string;
  onClick?: () => void;
}

const ButtonIcon = (props: PropsModel) => {
  const { className = '', icon, label, onClick } = props;
  const { t } = useTranslation();

  return (
    <div className={`${className} button-icon`} onClick={onClick}>
      <img src={icon} alt="atHome"></img>
      <span>{label}</span>
    </div>
  );
};

export default ButtonIcon;
