import { Radio } from '@swift-247/s247.library.ui-core';
import images from 'Assets/images';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { createSearchParams, useNavigate, useParams } from 'react-router-dom';
import { AddressPathEnum } from 'Root/routes/enums';
import { DTOAddress } from 'Services/v1/address-book/dto';
import * as addressBook from 'Stores/address-book';
import { classPrefix } from 'Utilities/global';
interface AddressListProps {
  title?: string;
  subTitle?: string;
  disabled?: boolean;
  data: DTOAddress[];
  onSelect?: any;
  onEdit?: any;
  selectedItem?: DTOAddress;
  isSelectable?: boolean;
}

interface AddressItemProps {
  disabled?: boolean;
  isSelected?: boolean;
  onSelect?: any;
  onEdit?: any;
  data: DTOAddress;
  className?: string;
  isSelectable?: boolean;
}

const AddressItem = (props: AddressItemProps) => {
  const navigate = useNavigate();
  const {
    disabled,
    isSelected,
    onSelect,
    onEdit,
    data,
    className,
    isSelectable
  } = props;
  const {
    personName,
    phone,
    address,
    id,
    type,
    isDefault,
    province,
    country,
    addressDetail
  } = data;

  const compClass = `${classPrefix}-address-listing`;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { addressType } = useParams();

  const goToDetailAddress = () => {
    dispatch(addressBook.getAddressBookDetail(data));
    navigate({
      pathname: AddressPathEnum.AddressFormUpdate.replace(':id', data.id || ''),
      search: createSearchParams({
        addressType: addressType || 'NORMAL',
        orderType: 'c2c'
      })?.toString()
    });
  };

  return (
    <div className={`${compClass}-item pb-6 ${className}`}>
      <div className="pt-4 d-flex justify-content-start gap-2">
        {disabled || !isSelectable ? null : (
          <Radio
            checked={isSelected}
            onChange={() => null}
            onClick={onSelect}
          />
        )}
        <div>
          <span className="fw-bold">{personName}</span>
          {phone && <span> - {phone}</span>}
        </div>
      </div>

      <div className="pt-3">
        {addressDetail ? addressDetail + ' ' + address : address}
      </div>
      <div className="pt-3">
        {isDefault && (
          <label
            className={`${compClass}-item-tag ${compClass}-item-tag--primary`}
          >
            {type && type === 'SENDER'
              ? t('label_default_sender_address')
              : t('label_default_receiver_address')}
          </label>
        )}
      </div>
      {data.isForceUpdateAddress && (
        <div className="pt-3">
          <em className="d-flex align-items-center">
            <img
              className="mr-2"
              src={images.warningAddressIcon}
              alt="warning address"
            />
            <span
              className="text-danger cursor-pointer"
              onClick={goToDetailAddress}
            >
              {t('global_confirm_address')}
            </span>
          </em>
        </div>
      )}
      {id && (
        <span
          onClick={onEdit}
          className={`${compClass}-item-edit-btn cursor-pointer`}
        >
          {t('buttons_edit')}
        </span>
      )}
    </div>
  );
};

export const AddressList = (props: AddressListProps) => {
  const { t } = useTranslation();
  const {
    title,
    subTitle,
    disabled,
    data,
    onSelect,
    selectedItem,
    onEdit,
    isSelectable
  } = props;
  if (!data || data.length === 0) return <></>;

  return (
    <div>
      <div>
        {title && (
          <div className="p-5 text-center">
            <b>{t(title)}</b>
          </div>
        )}
        {subTitle && (
          <div className="text-gray-primary text-center pb-5">
            {t(subTitle)}
          </div>
        )}
      </div>
      <div>
        {data.map(item => {
          return (
            <AddressItem
              isSelectable={isSelectable}
              onEdit={() => onEdit(item)}
              isSelected={selectedItem?.id === item.id}
              onSelect={() => onSelect(item)}
              key={item.id}
              disabled={disabled}
              data={item}
              className={disabled ? 'text-gray-primary' : ''}
            />
          );
        })}
      </div>
    </div>
  );
};
