import { C2cDeliveryMethod, C2cPromoCode, ProductClassification, ProductSizeAndWeight } from "./components";
import { C2cOrderPaymentContainer } from "./containers";


export {
  C2cOrderPaymentContainer,
  ProductClassification,
  ProductSizeAndWeight,
  C2cDeliveryMethod,
  C2cPromoCode,
};
