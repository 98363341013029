import { ItemBaseModel } from 'Components/Models';
import { MainPageEnum } from 'Routes/index';
import swiftIcon from 'Assets/icon/order-swift.svg';
import internationalIcon from 'Assets/icon/order-international.svg';
import cargoDirectIcon from 'Assets/icon/order-cargo-direct.svg';

export const TABS_HEADER: Array<ItemBaseModel> = [
	{
		name: 'create_order_swift247',
		field: MainPageEnum.Swift247,
		img: swiftIcon,
		disabled: false,

	},
	{
		name: 'create_order_cargo_direct',
		field: MainPageEnum.CargoDirect,
		img: cargoDirectIcon,
		disabled: false,
	},
];