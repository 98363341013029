import { MessageAlertModel } from 'Components/Models';

export interface C2cValidationFieldModel {
  productCategories?: MessageAlertModel;
  weight?: MessageAlertModel;
  size?: MessageAlertModel;
  paymentMethod?: MessageAlertModel;
	address?: MessageAlertModel,
  termAndConditionAgreed?: MessageAlertModel
}

export interface C2cFieldValueModel {
  key: 'productCategories' | 'weight' | 'size' | 'paymentMethod' | 'termAndConditionAgreed';
  value?: MessageAlertModel;
}
