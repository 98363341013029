export interface ExtraServiceQueryModel {
    shippingFee?: number;
    weight: number;
    locationFrom: number | string
    locationTo: number | string
}

export interface ExtraServiceModel {
  name?: string;
  price?: string;
  feeCode?: string
}

export interface ExtraServiceResponse {
  data?: Array<ExtraServiceModel>,
  price?: number
}
