import ButtonIcon from "./buttonIcon/buttonIcon";
import CommentPopup from "./commentPopup/commentPopup";
import ConfirmPopup from "./confirmPopup/confirmPopup";
import DeliveryAddress from "./deliveryAddress/deliveryAddress";
import DeliveryMethodClassic from "./deliveryMethodClassic/deliveryMethodClassic";
import PromoVoucher from "./promoVoucher/promoVoucher";
import TermAndConditionAgreed from "./termAndConditionAreed/termAndConditionAgreed";
import FeeRecord from "./feeRecord/feeRecord";
import TransactionDetail from "./transactionDetail/transactionDetail";

export {
  DeliveryMethodClassic,
  DeliveryAddress,
  TransactionDetail,
  ButtonIcon,
  CommentPopup,
  PromoVoucher,
  ConfirmPopup,
  TermAndConditionAgreed,
  FeeRecord,
};
