export interface C2cDropPointModel {
  id: number;
  code: string;
  name: string;
  address: string;
  encodedAddress: string;
  geo: {
    type: string;
    coordinates: number[];
  };
  createdAt: string;
  updatedAt: string;
  provinceCode: string;
  type: number;
  active: boolean;
  startTime: string;
  endTime: string;
  activeTime: string;
  workHourStart: string;
  workHourEnd: string;
  phone: string;
  readyPickupStart: string;
  readyPickupEnd: string;
  readyDeliveryStart: string;
  readyDeliveryEnd: string;
  workDay: string[];
}

export interface C2cDropPointRawModel {
  id: number;
  code: string;
  name: string;
  address: string;
  encoded_address: string;
  geo: {
    type: string;
    coordinates: number[];
  };
  created_at: string;
  updated_at: string;
  province_code: string;
  type: number;
  active: boolean;
  start_time: string;
  end_time: string;
  active_time: string;
  work_hour_start: string;
  work_hour_end: string;
  phone: string;
  ready_pickup_start: string;
  ready_pickup_end: string;
  ready_delivery_start: string;
  ready_delivery_end: string;
  work_day: string[];
}

export interface SelectPointModel {
  id?: number;
  name?: string;
  address?: string;
  latitude?: number;
  longitude?: number;
  code?: string;
  point?: C2cDropPointModel;
}
