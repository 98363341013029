import { ReactNode } from 'react';
import { NavLink } from 'react-router-dom';
import classnames from 'Utilities/classnames';
import { classPrefix } from 'Utilities/global';
import './index.scss';

export interface MenuItemProps {
	text: string;
	icon?: ReactNode,
	link?: string,
	action?: any
}

export interface MenuItemListProps {
	items: MenuItemProps[],
	className?: string
}

const MenuItemList = (props: MenuItemListProps) => {
	const {
		items,
		className
	} = props;

	const containerClasses = classnames(`${classPrefix}-menu-item-list`, className)

	return (
		<div className={containerClasses}>
			{items.map((item, k) => (
				<div className={`${classPrefix}-menu-item-list-item d-flex align-items-center mb-4`} key={k}>
					{item.link ? <NavLink
						className={({ isActive }) =>
							isActive ? `${classPrefix}-menu-item-list-item-link ${classPrefix}-menu-item-list-item-link--active` : `${classPrefix}-menu-item-list-item-link`
						}
						to={item.link}
					>
            <span className="d-flex align-items-center">
              {item.icon && <span className="mr-2 lh-0">{item.icon}</span>}
              {item.text}
            </span>
					</NavLink> : <div onClick={item.action || (() => undefined)} className={`${classPrefix}-menu-item-list-item-link`}>{item.text}</div>}
				</div>
			))}
		</div>
	);
}


export default MenuItemList;