import {
  createEntityAdapter,
  createSlice,
  EntityState,
  PayloadAction
} from '@reduxjs/toolkit';
import { MessageAlertModel } from 'Components/Models';
import { C2cCategoryPackageTypeEnum } from 'Pages/C2cOrderPayment/enums';
import { TypeOrder } from 'Stores/c2c-order-payment/enums';
import { BaseExternalSourceState, GetFailedActionPayload } from 'Stores/models';
import { NewOrderState } from 'Stores/order-bill/models';
import { RootState } from '..';
import {
  C2C_WEIGHT_AND_SIZE_INIT,
  EXTRA_SERVICE_INIT,
  InitC2cValidationValue
} from './init-default-value';
import {
  C2cCheckDimWeightValidationModel,
  C2cFieldValueModel,
  C2cGoodsCategoriesModel,
  C2cProductModel,
  C2cProductTypesModel,
  C2cValidationFieldModel,
  C2cVoucherModel,
  C2cVouchersQueryModel,
  C2CVoucherValidationModel,
  C2cWeightAndSizeModel,
  DeclarationPolicyAgreementModel,
  ExtraServiceModel,
  ExtraServiceQueryModel,
  ExtraServiceResponse,
  GoodProduceType,
  GoodsInfoModel,
  IPaymentKorea,
  ProductDeclarationByCategory,
  SelectPointModel, VirtualC2CProductModel,
} from './models';
import {
  C2cDeliveryMethodModel,
  C2cPaymentConfig
} from './models/c2cDeliveryModel';
import { DTOSuggestedDimension } from 'Services/v1/c2c-order-payment/dto';
import { CamelSnakeMapper } from 'Services/data-mappers';

const productDeclarationAdapter = createEntityAdapter<GoodsInfoModel>({
  // Assume IDs are stored in a field other than `book.id`
  selectId: product => product.id
  // Keep the "all IDs" array sorted based on book titles
});


const productDeclarationByCategoryAdapter = createEntityAdapter<ProductDeclarationByCategory>({
  // Assume IDs are stored in a field other than `book.id`
  selectId: product => product.id
  // Keep the "all IDs" array sorted based on book titles
});

export interface C2cOverPaymentState {
  extraServices: ExtraServiceResponse;
  extraServiceSelected: Array<ExtraServiceModel>;
  goodsCategories: Array<C2cGoodsCategoriesModel>;
  weightAndSize: C2cWeightAndSizeModel;
  categorySelected: C2cGoodsCategoriesModel;
  vouchers: Array<C2cVoucherModel>;
  voucherSelected: C2cVoucherModel;
  voucherValidation: C2CVoucherValidationModel;
  deliveriesMethod: Array<C2cDeliveryMethodModel>;
  paymentConfig: C2cPaymentConfig[];
  deliveryMethodSelected?: C2cDeliveryMethodModel;
  productTypes: C2cProductTypesModel;
  productSelected: VirtualC2CProductModel;
  products: Array<C2cProductModel>;
  packageTypeSelected: C2cCategoryPackageTypeEnum;
  priceDelivery: number;
  totalPrice: number;
  supperFee: number;
  typeOrder: TypeOrder;
  selectReceiverPoint: SelectPointModel;
  selectSenderPoint: SelectPointModel;
  note: string;
  chosenPaymentMethod: C2cPaymentConfig;
  c2cValidateFields: C2cValidationFieldModel;
  metaDataOrderResponse: any;
  metaCustomPaymentData: any;
  isLoading: boolean;
  deliveryMethodFetchingState: BaseExternalSourceState;
  isFirstTimeInputC2C: boolean;
  isFirstTimeInputGlobal: boolean;
  productDeclaration: EntityState<GoodsInfoModel>;
  productDeclarationByCategory: EntityState<ProductDeclarationByCategory>;
  newOrder?: NewOrderState;
  declarationPolicyAgreement: DeclarationPolicyAgreementModel;
  toggleOrder: boolean;
  checkDimWeight: C2cCheckDimWeightValidationModel;
  isGlobal: boolean;
  globalDirection?: 'inbound' | 'outbound';
  globalCountry?: string;
  isTermAndConditionAgreed: boolean;
  suggestedDimensionList?: DTOSuggestedDimension[]
  productCategoryTypes: {
    parcel?: string[],
    document?: string[]
  }
  productCategoryTypeSelected?: string
}

const initialState: C2cOverPaymentState = {
  goodsCategories: [],
  extraServices: EXTRA_SERVICE_INIT,
  extraServiceSelected: [],
  weightAndSize: C2C_WEIGHT_AND_SIZE_INIT,
  categorySelected: {},
  vouchers: [],
  voucherSelected: {},
  deliveriesMethod: [],
  paymentConfig: [],
  deliveryMethodSelected: {},
  productTypes: {},
  productSelected: {},
  products: [],
  packageTypeSelected: C2cCategoryPackageTypeEnum.Parcel,
  priceDelivery: 0,
  totalPrice: 0,
  supperFee: 0,
  typeOrder: TypeOrder.BASIC,
  selectReceiverPoint: {},
  selectSenderPoint: {},
  note: '',
  voucherValidation: {},
  chosenPaymentMethod: {} as C2cPaymentConfig,
  c2cValidateFields: InitC2cValidationValue,
  metaDataOrderResponse: {},
  metaCustomPaymentData: {},
  isLoading: false,
  deliveryMethodFetchingState: {
    fetchingStatus: 'init',
    kind: 'general'
  },
  isFirstTimeInputC2C: true,
  isFirstTimeInputGlobal: true,
  productDeclaration: productDeclarationAdapter.getInitialState(),
  productDeclarationByCategory: productDeclarationByCategoryAdapter.getInitialState(),
  declarationPolicyAgreement: {},
  toggleOrder: false,
  checkDimWeight: {} as C2cCheckDimWeightValidationModel,
  isGlobal: false,
  isTermAndConditionAgreed: false,
  productCategoryTypes: {},
};

const totalPrice = (state: C2cOverPaymentState) => {
	if(!state.priceDelivery) {
		state.totalPrice = 0;
		return;
	}
  state.totalPrice =
    state.priceDelivery +
    Number(state.deliveryMethodSelected?.dutyFee || 0) +
    state.extraServiceSelected.reduce(
      (acc, cur) => acc + Number(cur?.price),
      0
    ) +
    Number(state?.productSelected?.price || 0) -
    Number(state?.deliveryMethodSelected?.discount || 0);
};

const getExtraFees = (deliveryMethodSelected?: C2cDeliveryMethodModel):ExtraServiceModel[] => {
  if(!deliveryMethodSelected?.feeOptions) return [];
  return deliveryMethodSelected.feeOptions.map(item => {
    return {
      name: item.feeName,
      price: Math.max(parseInt(item.amount + ''), parseInt(item.minCharge + '')).toString(),
      feeCode: item.feeCode
    }
  })
}

export const c2cOrderPaymentSlice = createSlice({
  name: 'c2cOverPayment',
  initialState,
  reducers: {
    getGetExtraService: (
      state,
      action: PayloadAction<ExtraServiceQueryModel>
    ) => {},

    getGetExtraServiceSuccess: (
      state,
      action: PayloadAction<ExtraServiceResponse>
    ) => {
      state.extraServices = action.payload;
      state.extraServiceSelected =
        action.payload?.data?.filter(
          item =>
            !!state?.extraServiceSelected?.find(
              itemSelected => item?.name === itemSelected?.name
            )
        ) || [];
    },

    chooseExtraServiceAction: (
      state,
      action: PayloadAction<ExtraServiceModel>
    ) => {
      state.extraServiceSelected.push(action.payload);
      totalPrice(state);
    },

    removeExtraServiceAction: (
      state,
      action: PayloadAction<ExtraServiceModel>
    ) => {
      state.extraServiceSelected = state.extraServiceSelected.filter(
        service => service.name !== action.payload.name
      );
      totalPrice(state);
    },
    // --
    onChangeWeightAction: (
      state,
      action: PayloadAction<number | undefined>
    ) => {
      if (!action?.payload) {
        state.deliveriesMethod = [];
        state.deliveryMethodSelected = {};
        state.extraServices = EXTRA_SERVICE_INIT;
        state.extraServiceSelected =
          EXTRA_SERVICE_INIT.data?.filter(
            item =>
              !!state?.extraServiceSelected?.find(
                itemSelected => item?.name === itemSelected?.name
              )
          ) || [];
      }
      state.weightAndSize.weight = action.payload;
      // state.isLoading = true;
    },

    onChangeLongAction: (state, action: PayloadAction<number | undefined>) => {
      state.weightAndSize.longs = action.payload;
      // state.isLoading = true;
    },

    onChangeWidthAction: (state, action: PayloadAction<number | undefined>) => {
      state.weightAndSize.width = action.payload;
      // state.isLoading = true;
    },

    onChangeHeightAction: (
      state,
      action: PayloadAction<number | undefined>
    ) => {
      state.weightAndSize.height = action.payload;
      // state.isLoading = true;
    },
    setWeightLongWidthHeight: (
      state,
      action: PayloadAction<{
        weight: number;
        long: number;
        width: number;
        height: number;
      }>
    ) => {
      state.weightAndSize.weight = action.payload.weight;
      state.weightAndSize.longs = action.payload.long;
      state.weightAndSize.width = action.payload.width;
      state.weightAndSize.height = action.payload.height;
    },
    setSize: (
      state,
      action: PayloadAction<{
        long: number;
        width: number;
        height: number;
      }>
    ) => {
      state.weightAndSize.longs = action.payload.long;
      state.weightAndSize.width = action.payload.width;
      state.weightAndSize.height = action.payload.height;
    },
    //--voucher
    getC2cVouchersAction: (
      state,
      action: PayloadAction<C2cVouchersQueryModel>
    ) => {},
    getC2cVouchersSuccess: (
      state,
      action: PayloadAction<Array<C2cVoucherModel>>
    ) => {
      state.vouchers = action.payload;
    },

    //--delivery
    getC2cDeliveriesMethodAction: (state, action: PayloadAction<any>) => {
      state.deliveryMethodFetchingState.fetchingStatus = 'pending';
    },
    getC2cDeliveriesSuccess: (
      state,
      action: PayloadAction<{
        data: C2cDeliveryMethodModel[];
        paymentConfig: C2cPaymentConfig[];
      }>
    ) => {
      state.deliveryMethodFetchingState.fetchingStatus = 'success';
      state.deliveryMethodFetchingState.errMsg = undefined;
      state.deliveryMethodFetchingState.errCode = undefined;
      state.deliveriesMethod = action.payload?.data;
      state.paymentConfig = action.payload?.paymentConfig || [];
      state.checkDimWeight = {} as C2cCheckDimWeightValidationModel;
      if (
        !state.deliveryMethodSelected ||
        Object.keys(state.deliveryMethodSelected).length === 0
      ) {
        state.deliveryMethodSelected =
          action.payload && action.payload?.data.length > 0
            ? action.payload?.data[0]
            : {};
        state.extraServices = {
          data: getExtraFees(state.deliveryMethodSelected)
        }
      }
      state.priceDelivery = action?.payload?.data?.[0]?.price || 0;
      totalPrice(state);
    },
    getC2CDeliveryMethodFailed: (
      state,
      action: PayloadAction<GetFailedActionPayload>
    ) => {
      if (action.payload.data?.isCheckDimWeight) {
        state.checkDimWeight.errCode = action.payload.errCode;
        state.checkDimWeight.errMsg = action.payload.errMsg;
        state.checkDimWeight.isCheckDimWeight = true;
      } else {
        state.deliveryMethodFetchingState.fetchingStatus = 'error';
        state.deliveryMethodFetchingState.errMsg = action.payload.errMsg;
        state.deliveryMethodFetchingState.errCode = action.payload.errCode;
      }
      state.deliveriesMethod = [];
      state.paymentConfig = [];
      state.deliveryMethodSelected = {};
      state.priceDelivery = 0;
      state.chosenPaymentMethod = {};
      totalPrice(state);
    },
    chooseDeliveryMethodAction: (
      state,
      action: PayloadAction<C2cDeliveryMethodModel>
    ) => {
      state.deliveryMethodSelected = action.payload;
      state.priceDelivery = action?.payload?.price || 0;
      if (state?.deliveryMethodSelected) {
        const { items } = state?.deliveryMethodSelected;
        if (
          items?.length &&
          items[0].receivedTimeSlots?.length &&
          items[0]?.receivedTimeSlots[0]?.slots &&
          items[0]?.receivedTimeSlots[0]?.slots?.length
        ) {
          state.supperFee =
            items[0]?.receivedTimeSlots[0]?.slots[0]?.superFee || 0;
        }

        state.extraServices = {
          data: getExtraFees(state.deliveryMethodSelected)
        }
      }
      totalPrice(state);
    },

    // --product
    getC2cProductTypesAction: (
      state,
      action: PayloadAction<GoodProduceType>
    ) => {},

    getC2cProductTypesSuccess: (
      state,
      action: PayloadAction<C2cProductTypesModel>
    ) => {

      state.productTypes.parcel = action?.payload?.parcel;
      state.productTypes.document = action?.payload?.document;
      state.productCategoryTypes.parcel = Object.keys(action?.payload?.parcel || {});
      state.productCategoryTypes.document = Object.keys(action?.payload?.document || {});
    },

    chooseC2cProductAction: (state, action: PayloadAction<VirtualC2CProductModel | Record<string, never>>) => {
      state.productSelected = action.payload;
    },

    chooseC2CProductCategory: (state, action: PayloadAction<string>) => {
      state.productCategoryTypeSelected = action.payload;
      const productListByType = state?.packageTypeSelected === C2cCategoryPackageTypeEnum.Parcel ? (state?.productTypes?.parcel) : (state?.productTypes?.document);
      state.products = productListByType ? productListByType[action.payload].map(CamelSnakeMapper.withRecursion(CamelSnakeMapper.toCamelCase)) as any : [];
    },

    choosePackageTypeAction: (
      state,
      action: PayloadAction<C2cCategoryPackageTypeEnum>
    ) => {
      state.packageTypeSelected = action.payload;
      state.productSelected = {};
      // state.products =
      //   action.payload === C2cCategoryPackageTypeEnum.Parcel
      //     ? (state?.productTypes?.parcel as Array<C2cProductModel>)
      //     : (state?.productTypes?.document as Array<C2cProductModel>);
      state.isFirstTimeInputC2C = true;
      state.isFirstTimeInputGlobal = true;
      state.weightAndSize = {};
      state.productDeclarationByCategory = productDeclarationByCategoryAdapter.getInitialState();
    },

    updateProductsAction: (
      state,
      action: PayloadAction<Array<C2cProductModel>>
    ) => {
      state.products = action.payload;
    },

    postC2cOrderAction: (
      state,
      action: PayloadAction<(isGlobal: boolean, data: IPaymentKorea) => void>
    ) => {},

    postC2cOrderSuccessAction: (state, action: PayloadAction<any>) => {
      // state.metaDataOrderResponse = action.payload;
      // state.metaCustomPaymentData =
      // 	action.payload?.meta?.custom?.paymentData;
    },

    postC2cOrderFailedAction: (state, action: PayloadAction<any>) => {},

    selectReceiverPointAction: (
      state,
      action: PayloadAction<SelectPointModel>
    ) => {
      state.selectReceiverPoint = action.payload;
    },

    selectSenderPointAction: (
      state,
      action: PayloadAction<SelectPointModel>
    ) => {
      state.selectSenderPoint = action.payload;
    },

    chooseVoucherAction: (state, action: PayloadAction<C2cVoucherModel>) => {
      state.voucherSelected = action.payload;
    },
    updateVoucherValidation: (
      state,
      action: PayloadAction<C2CVoucherValidationModel>
    ) => {
      state.voucherValidation = action.payload;
    },
    setNoteAction: (state, action: PayloadAction<string>) => {
      state.note = action.payload;
    },
    updateOrderTypeAction: (state, action: PayloadAction<string>) => {
      if (action.payload === TypeOrder.SUPER) {
        state.packageTypeSelected = C2cCategoryPackageTypeEnum.Document;
        state.products = state.productTypes.document as Array<C2cProductModel>;
      } else {
        state.packageTypeSelected = C2cCategoryPackageTypeEnum.Parcel;
        state.products = state.productTypes.parcel as Array<C2cProductModel>;
      }
      state.typeOrder = action.payload as TypeOrder;
    },
    selectPaymentMethodAction: (
      state,
      action: PayloadAction<C2cPaymentConfig>
    ) => {
      state.chosenPaymentMethod = action.payload;
    },

    checkValidationInputAction: (
      state,
      action: PayloadAction<C2cValidationFieldModel>
    ) => {
      state.c2cValidateFields = action.payload;
    },
    checkValidationProductDeclaration: (
      state,
      action: PayloadAction<MessageAlertModel>
    ) => {
      state.c2cValidateFields.productCategoryTypes = action.payload;
    },
    updateIsTermAndConditionAgreedAction: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isTermAndConditionAgreed = action.payload;
    },
    setC2cValidationInputByField: (
      state,
      action: PayloadAction<C2cFieldValueModel>
    ) => {
      if (state.c2cValidateFields && action.payload) {
        state.c2cValidateFields[action.payload.key] = action.payload.value;
      }
    },
    updateIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    resetC2cOrderPayment: (state, action?: PayloadAction<boolean>) => {
      if (action?.payload) {
        return initialState;
      } else {
        return {
          ...initialState,
          products: state?.products,
          productTypes: state?.productTypes,
          packageTypeSelected: state?.packageTypeSelected
        };
      }
    },
    setIsFirstTimeInputC2C: (state, action: PayloadAction<boolean>) => {
      state.isFirstTimeInputC2C = action.payload;
    },
    setIsFirstTimeInputGlobal: (state, action: PayloadAction<boolean>) => {
      state.isFirstTimeInputGlobal = action.payload;
    },

    addProductDeclaration: (state, action: PayloadAction<GoodsInfoModel>) => {
      productDeclarationAdapter.addOne(
        state.productDeclaration,
        action.payload
      );
    },
    removeProductDeclaration: (state, action: PayloadAction<string>) => {
      productDeclarationAdapter.removeOne(
        state.productDeclaration,
        action.payload
      );
    },
    upsertProductDeclaration: (
      state,
      action: PayloadAction<GoodsInfoModel>
    ) => {
      productDeclarationAdapter.setOne(
        state.productDeclaration,
        action.payload
      );
    },

    addProductDeclarationByCategory: (state, action: PayloadAction<ProductDeclarationByCategory>) => {
      productDeclarationByCategoryAdapter.addOne(
        state.productDeclarationByCategory,
        action.payload
      );
    },
    removeProductDeclarationByCategory: (state, action: PayloadAction<string>) => {
      productDeclarationByCategoryAdapter.removeOne(
        state.productDeclarationByCategory,
        action.payload
      );
    },
    upsertProductDeclarationByCategory: (
      state,
      action: PayloadAction<ProductDeclarationByCategory>
    ) => {
      productDeclarationByCategoryAdapter.setOne(
        state.productDeclarationByCategory,
        action.payload
      );
    },

    removeAllProductDeclarationByCategory: (state) => {
      productDeclarationByCategoryAdapter.removeAll(state.productDeclarationByCategory);
    },

    updateNewOrder: (state, action: PayloadAction<NewOrderState>) => {
      if (!state.newOrder) {
        state.newOrder = action.payload;
        return;
      }
      if (action.payload.status) {
        state.newOrder.status = action.payload.status;
      }

      if (action.payload.order) {
        state.newOrder.order = action.payload.order;
      }

      if (action.payload.errorMsg) {
        state.newOrder.errorMsg = action.payload.errorMsg;
      }
    },
    removeNewOrder: state => {
      delete state.newOrder;
    },
    setDeclarationPolicyAgreement: (
      state,
      action: PayloadAction<DeclarationPolicyAgreementModel>
    ) => {
      state.declarationPolicyAgreement = action.payload;
    },
    setToggleOrder: state => {
      state.toggleOrder = !state.toggleOrder;
    },
    setCheckDimWeight: (
      state,
      action: PayloadAction<C2cCheckDimWeightValidationModel>
    ) => {
      state.checkDimWeight = action.payload;
    },
    setIsGlobal: (state, action: PayloadAction<boolean>) => {
      state.isGlobal = action.payload;
    },
    setGlobalDirection: (
      state,
      action: PayloadAction<'inbound' | 'outbound'>
    ) => {
      state.globalDirection = action.payload;
    },
    setGlobalCountry: (state, action: PayloadAction<string>) => {
      state.globalCountry = action.payload;
    },
    getSuggestedDimensionList: () => {
    },
    setSuggestedDimensionList: (state, action: PayloadAction<DTOSuggestedDimension[]>) => {
      state.suggestedDimensionList = action.payload;
    }
  }
});

export const {
  getGetExtraService,
  getGetExtraServiceSuccess,
  chooseExtraServiceAction,
  removeExtraServiceAction,
  onChangeWeightAction,
  onChangeLongAction,
  onChangeWidthAction,
  onChangeHeightAction,
  setWeightLongWidthHeight,
  setSize,
  getC2cVouchersAction,
  getC2cVouchersSuccess,
  getC2cDeliveriesMethodAction,
  getC2cDeliveriesSuccess,
  chooseDeliveryMethodAction,
  getC2cProductTypesAction,
  getC2cProductTypesSuccess,
  chooseC2cProductAction,
  choosePackageTypeAction,
  updateProductsAction,
  postC2cOrderAction,
  postC2cOrderSuccessAction,
  selectReceiverPointAction,
  selectSenderPointAction,
  chooseVoucherAction,
  setNoteAction,
  updateVoucherValidation,
  updateOrderTypeAction,
  selectPaymentMethodAction,
  checkValidationInputAction,
  checkValidationProductDeclaration,
  updateIsTermAndConditionAgreedAction,
  setC2cValidationInputByField,
  postC2cOrderFailedAction,
  updateIsLoading,
  resetC2cOrderPayment,
  getC2CDeliveryMethodFailed,
  setIsFirstTimeInputC2C,
  setIsFirstTimeInputGlobal,
  addProductDeclaration,
  removeProductDeclaration,
  upsertProductDeclaration,
  updateNewOrder,
  removeNewOrder,
  setDeclarationPolicyAgreement,
  setToggleOrder,
  setCheckDimWeight,
  setIsGlobal,
  setGlobalDirection,
  setGlobalCountry,
  addProductDeclarationByCategory,
  removeProductDeclarationByCategory,
  upsertProductDeclarationByCategory,
  setSuggestedDimensionList,
  getSuggestedDimensionList,
  chooseC2CProductCategory,
  removeAllProductDeclarationByCategory
} = c2cOrderPaymentSlice.actions;

const productDeclarationSelector =
  productDeclarationAdapter.getSelectors<C2cOverPaymentState>(
    state => state.productDeclaration
  );

const productDeclarationByCategorySelector =
  productDeclarationByCategoryAdapter.getSelectors<RootState>(
    state => state.c2cOrderPayment.productDeclarationByCategory
  );

export const c2cOrderPaymentSelectors = {
  get: (state: RootState) => state.c2cOrderPayment,
  getAllProductDeclaration: (state: RootState) =>
    productDeclarationSelector.selectAll(state.c2cOrderPayment),
  getOneProductDeclaration: (state: RootState, id: string) =>
    productDeclarationSelector.selectById(state.c2cOrderPayment, id),
  productDeclarationByCategorySelector: productDeclarationByCategorySelector
};
const C2cOrderPaymentReducer = c2cOrderPaymentSlice.reducer;

export default C2cOrderPaymentReducer;
