import { DeliveryTypeEnum } from 'Pages/C2cOrderPayment/enums';
import { GoodsInfoModel } from './c2cGoodsInfoModel';

export interface C2cDeliveryQueryModel {
	category?: any;
	destinationId?: Array<string>;
	dimension?: string;
	fromAddr?: string;
	fromLat?: number;
	fromLng?: number;
	interval?: string;
	location?: string;
	nDay?: string;
	originId?: Array<string>;
	packageType?: string;
	premium?: boolean;
	productId?: string;
	serviceType?: string;
	super?: boolean;
	toAddr?: string;
	toLat?: number;
	toLng?: number;
	voucher?: string;
	weight?: number;
	goodsInfo?: GoodsInfoModel[];
  receiverAddressId?: number;
  senderAddressId?: number;
}

export interface C2CFeeOption {
	feeName: string
	feeCode: string
	applyVat: boolean,
	amount: number,
	minCharge: number,
	unit: string,
	applyCondition: string,
	total: number
}

export interface C2cDeliveryMethodModel {
	avaiPaymentMethods?: Array<string>;
	currency?: string;
	deliveryType?: DeliveryTypeEnum;
	destination?: string;
	destinationArea?: string;
	destinationDop?: number;
	discount?: number;
	estDateTime?: Date;
	estTime?: number;
	firstMile?: string;
	firstTime?: Date;
	fromTime?: number;
	internalPrice?: number;
	items?: Array<DeliveryTimeInfoModel>;
	lastMile?: number;
	lastTime?: Date;
	lineHaul?: number;
	lineTime?: Date;
	object?: string;
	origin?: string;
	originArea?: string;
	originDop?: number;
	price?: number;
	priceSurchargeDestination?: number;
	priceSurchargeOrigin?: number;
	proposal?: number;
	toTime?: number;
	transportRule?: string;
	dutyFee?: number;
  toDutyFee?: number;
  toCurrency?: string;
  toPrice?: number;
	rate?: number;
	feeOptions: C2CFeeOption[]
	fees: any
}

export interface ATMBank {
	code: string;
	icon: string;
	id: number;
	name: string;
	status: boolean;
	uid: string;
  urlImage: string;
}

export interface C2cPaymentMethod {
	id?: number;
	code?: string;
	codeParent?: string;
	description?: string;
	icon?: string;
	name?: string;
	status?: boolean;
	uid?: string;
	banks?: ATMBank[];
}

export interface C2cPaymentConfig extends C2cPaymentMethod {
	id?: number;
	code?: string;
	currency?: string;
	icon?: string;
	isShow?: boolean;
	name?: string;
	methods?: C2cPaymentMethod[];
	auth?: { icon: string };
}

export interface DeliveryTimeInfoModel {
	before?: string;
	minEndTime?: string;
	estTime?: string;
	receivedTimeSlots?: Array<ReceivedTimeSlotModel>;
}

export interface ReceivedTimeSlotModel {
	date?: string;
	slots?: Array<ReceivedTimeSlotItemModel>;
}

export interface ReceivedTimeSlotItemModel {
	enable?: boolean;
	superFee?: number;
	time?: { start: string; end: string };
}
