import SelectAddress from 'Components/SelectAddress';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useNavigate } from 'react-router-dom';
import AddressBookServiceV1 from 'Services/v1/address-book';
import { DTOAddress } from 'Services/v1/address-book/dto';
import { addressBookSelectors, getAddressBook } from 'Stores/address-book';
import {
  orderBillSelectors, updateReceiverAddress,
  updateSenderAddress
} from 'Stores/order-bill';
import { AddressPathEnum } from '../../../routes/enums';
import './index.scss';

export interface AddressInfoProps {
  className?: string;
  data?: DTOAddress;
  title: string;
  defaultText: string;
  onClick: () => void;
  errorMessage?: string;
  urlNavigationReturn?: string;
}

export interface FlightFromToIconProps {
  className?: string;
}

const OrderBillSelectAddress = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { receiverAddress, senderAddress } = useSelector(
    orderBillSelectors.get
  );
  const addressBook = useSelector(addressBookSelectors.get).list;

  const errorMessage = useMemo(() => {
    if (!senderAddress || !receiverAddress) return;
    if (senderAddress.id === receiverAddress.id)
      return t('validation_select_address_same');
    if (senderAddress.province === receiverAddress.province)
      return t('validation_select_address_same_province');
  }, [senderAddress, receiverAddress]);

  useEffect(() => {
    dispatch(
      getAddressBook({
        limit: 20,
        page: 0
      })
    );
  }, []);

  useEffect(() => {
    const defaultSenderAddress = addressBook.find(
      item => item.isDefault && item.type === 'SENDER'
    );
    if (!senderAddress && defaultSenderAddress) {
      dispatch(updateSenderAddress(defaultSenderAddress));
    }

    const defaultReceiverAddress = addressBook.find(
      item => item.isDefault && item.type === 'RECEIVER'
    );
    if (!receiverAddress && defaultReceiverAddress) {
      dispatch(updateReceiverAddress(defaultReceiverAddress));
    }

  }, [addressBook, senderAddress, receiverAddress]);

  useEffect(() => {
    if (senderAddress) {
      AddressBookServiceV1.getAddress(senderAddress.id).then(data => {
        dispatch(updateSenderAddress(data.data));
      });
    }
    if (receiverAddress) {
      AddressBookServiceV1.getAddress(receiverAddress.id).then(data => {
        dispatch(updateReceiverAddress(data.data));
      });
    }
  }, []);

  const onSenderClick = () => {
    navigate(
      generatePath(AddressPathEnum.AddressListingSelect, {
        orderType: 'cd',
        addressType: 'sender'
      })
    );
  };

  const onReceiverClick = () => {
    navigate(
      generatePath(AddressPathEnum.AddressListingSelect, {
        orderType: 'cd',
        addressType: 'receiver'
      })
    );
  };

  return (
    <SelectAddress
      senderAddress={senderAddress}
      receiverAddress={receiverAddress}
      errorMessage={errorMessage}
      onSenderClick={onSenderClick}
      onReceiverClick={onReceiverClick}
    />
  );
};

export default OrderBillSelectAddress;
