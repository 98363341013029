import { C2cProductModel } from ".";


export interface C2cGoodsCategoriesModel {
  code?: string;
  createdAt?: string;
  description?: string;
  id?: number;
  imgUrl?: string;
  langCode?: string;
  name?: string;
  object?: string;
  packageType?: string;
  productTypes?: Array<C2cProductModel>;
  status?: string;
  updatedAt?: string;
}
