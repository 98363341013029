import nextIcon from 'Assets/icon/next-icon.svg';
import previousIcon from 'Assets/icon/previous-icon.svg';
import ItemBase from 'Components/ItemBase/itemBase';
import { C2cCategoryPackageTypeEnum } from 'Pages/C2cOrderPayment/enums';
import { C2cProductModel, TypeOrder } from 'Stores/c2c-order-payment';

interface PropsModel {
  packageTypeSelected: C2cCategoryPackageTypeEnum;
  products: Array<C2cProductModel>;
  onChooseProduct: (item: C2cProductModel) => void;
  page: number;
  productSelected: C2cProductModel;
  typeOrder: TypeOrder;
  handleShowListProduct: (num: number) => void;
  size?: number;
  line?: number;
}

const ProductCarousel = (props: PropsModel) => {
  const {
    packageTypeSelected,
    products,
    onChooseProduct,
    page,
    productSelected,
    typeOrder,
    handleShowListProduct,
    size = 16,
    line = 2
  } = props;

  const isDisabledAndShowInfo = (item: C2cProductModel) =>
    !item?.isPremium && typeOrder == TypeOrder.PREMIUM;

  const renderListProduct = () => {
    return products
      ?.filter((product: C2cProductModel, index: number) => {
        return index < page * size && index >= (page - 1) * size;
      })
      ?.map((item: C2cProductModel, index: number) => {
        return (
          <ItemBase
            key={index}
            className="product-classification-item"
            active={item?.id === productSelected?.id}
            isInfo={isDisabledAndShowInfo(item)}
            disable={isDisabledAndShowInfo(item)}
            messageTooltip="leadtime_premium_product_not_support"
            item={{
              name: item?.product,
              img: item?.categoryImgUrl
            }}
            onClick={() => onChooseProduct(item)}
            style={{ width: `${100 / (size / line)}%` }}
          ></ItemBase>
        );
      });
  };
  return (
    <div
      className={
        packageTypeSelected === C2cCategoryPackageTypeEnum.Parcel
          ? 'product-classification-list product-classification-list-parcel'
          : 'product-classification-list'
      }
    >
      {renderListProduct()}
      {page < products?.length / size && (
        <div
          className="carousel-icon-container carousel-icon-container-next"
          onClick={() => handleShowListProduct(1)}
        >
          <img src={nextIcon} alt={''} />
        </div>
      )}
      {page !== 1 && (
        <div
          className="carousel-icon-container carousel-icon-container-previous"
          onClick={() => handleShowListProduct(-1)}
        >
          <img src={previousIcon} alt={''} />
        </div>
      )}
    </div>
  );
};

export default ProductCarousel;
