import { ProductTypeEnum } from "../enums/productTypeEnum";

export interface C2cProductModel {
  catId?: number;
  categoryCode?: string;
  categoryName?:string;
  categoryStatus?:string;
  code?: string;
  createdAt?: string;
  description?: string;
  id?: number;
  imgUrl?: string;
  isKorean?: boolean;
  isLocal?: boolean;
  isPremium?: boolean;
  isWarning?: boolean;
  langCode?: string;
  price?: string;
  product?: string;
  status?: string;
  trucking?: false;
  type?: ProductTypeEnum;
  updatedAt?: null;
  viewNo?: number;
  categoryImgUrl?: string;
}

export interface VirtualC2CProductModel {
  price: string
  ids: number[]
  product: string;
  code: string
  type?: ProductTypeEnum;
}

export interface C2cProductTypesModel {
  parcel?: {
    [key: string]: Array<C2cProductModel>
  };
  document?: {
    [key: string]: Array<C2cProductModel>
  };
}

