import InputText from 'Components/InputText';
import { C2cOverPaymentTextEnum } from 'Pages/C2cOrderPayment/enums';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import search from 'Assets/icon/search.svg';
import './styles.scss';
import { C2cProductModel } from 'Stores/c2c-order-payment';

interface PropsModel {
	productSelected: C2cProductModel;
	products: Array<C2cProductModel>;
	handleOnChangeInput: (e: React.ChangeEvent<HTMLInputElement>) => void;
	onChooseProduct: (p: C2cProductModel) => void;
    setOpenDropdown: (open: boolean)=>void;
    keyword:string;
    openDropdown: boolean;
}

const ProductSearchInput = (props: PropsModel) => {
	const { productSelected, products, handleOnChangeInput, onChooseProduct, keyword, openDropdown, setOpenDropdown } = props;
	const ref = useRef<{ contains: any }>() as any;
	const { t } = useTranslation();

	useEffect(() => {
		const handleClick = (e: any) => {
			const { target } = e;

			if (!ref?.current?.contains(target)) {
				setOpenDropdown(false);
			}
		};

		document.addEventListener('click', handleClick);

		return () => {
			document.removeEventListener('click', handleClick);
		};
	}, []);

	const renderListProductSearch = (products: Array<C2cProductModel>) => {
		const listRender = products
			?.filter((product, index) => {
				return index < 8;
			})
			?.map((product) => {
				return (
					<li
						key={product?.id}
						className={
							productSelected?.id === product?.id
								? 'pc-dropdown-search-item pc-dropdown-search-item-active'
								: 'pc-dropdown-search-item'
						}
						onClick={() => onChooseProduct(product)}
					>
						{product?.product}
					</li>
				);
			});
		if (!listRender?.length) {
			return <></>;
		}
		return <ul className="pc-dropdown-search">{listRender}</ul>;
	};
	return (
		<div className="product-classification-input-wrap" ref={ref}>
			<InputText
				className="product-classification-input"
				value={keyword}
				name="search-box"
				addonBefore={<img src={search} width="18" />}
				placeholder={t(
					C2cOverPaymentTextEnum.ProductClassification_PlaceHolderInput,
				)}
				onChange={(e) => handleOnChangeInput(e)}
				onFocus={() => {
					setOpenDropdown(true);
				}}
			/>
			{openDropdown && renderListProductSearch(products)}
		</div>
	);
};
export default ProductSearchInput;
