import { VOUCHER_STATUS } from 'Utilities/constants';

export interface C2cVoucherModel {
  airport?: string;
  arrivalAirport?: string;
  blogId?: string;
  category?: string;
  code?: string;
  content?: { key1?: string; key2?: string; langKey?: string };
  createdAt?: string;
  customerId?: number;
  deliveryType?: string;
  departureAirport?: string;
  description?: string;
  discountAmount?: number;
  discountType?: string;
  endAt?: string;
  fromLocationId?: Array<string>;
  fromTime?: string;
  id?: number;
  isActive?: boolean;
  isGlobal?: boolean;
  maxDiscountAmount?: number;
  minAmount?: number;
  showAllDatePromotionApply?: boolean;
  showDate?: boolean;
  showPromotionCode?: boolean;
  showPromotionExpried?: boolean;
  showPromotionInfo?: boolean;
  startAt?: string;
  timesUsed?: number;
  timesUsedDaily?: number;
  tittle?: string;
  toLocationId?: Array<string>;
  toTime?: string;
  updatedAt?: string;
  url?: string;
  usageLimit?: number;
  usageLimitDaily?: number;
  usageLimitPerCustomer?: number;
}

export interface C2cVouchersQueryModel {
  productId?: number;
  originId?: Array<number>;
  destinationId?: Array<number>;
}

export interface C2cVoucherCheckingAvailableQueryModel {
  voucher: string
}

export interface C2CVoucherValidationModel {
  status?: 'error' | 'success' | 'checking',
  message?: string
}