import { ReactElement } from 'react';

export interface PopupConfigModelCustomHeaderProps {
	onClose?: (data: any) => void;
}

export interface PopupConfigModel {
	okBtnLabel?: string;
	cancelBtnLabel?: string;
	icon?: string;
	title?: string;
	hasDivider?: boolean;
	width?: number | string;
	height?: number | string;
	minWidth?:number | string;
	minHeight?: number | string;
	maxWidth?:number | string;
	maxHeight?: number | string;
	hideBtnCancel?: boolean;
	hideBtnOk?: boolean;
	btnFullWidth?:boolean;
	btnReverse?: boolean;
	hideFooter?:boolean;
  className?: string;
  onSubmit?: (data: any) => void;
  onClose?: (data: any) => void;
	CustomHeader?: (props: PopupConfigModelCustomHeaderProps) => ReactElement
}
