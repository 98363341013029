import ButtonClick from 'Components/ButtonClick';
import CustomPopup from 'Components/CustomPopup';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import './styles.scss';

interface PropsModel {
	maxLength?: number;
	isOpen: boolean;
	className?: string;
	title?: string;
	onClose: () => void;
	onAccept: () => void;
	children: ReactNode;
}

const ConfirmPopup = (props: PropsModel) => {
	const {
		className = '',
		title,
		isOpen,
		onClose,
		onAccept,
		children,
	} = props;

	const { t } = useTranslation();

	return (
		<CustomPopup
			isOpen={isOpen}
			onClose={onClose}
			title={t(title || '')}
			className={`${className} confirm-popup`}
		>
			<div className="confirm-popup-content">{children}</div>
			<ButtonClick
				containerClassName="confirm-popup-btn"
				onClick={() => onAccept()}
			>
				{t('global_accept_action')}
			</ButtonClick>
		</CustomPopup>
	);
};

export default ConfirmPopup;
