import { ReactNode } from 'react';
import { PopupConfigModel } from './popupConfigModel';

export interface UsePopupModel {
	onOpen: (popupConfig: PopupConfigModel, reactNode: ReactNode) => void;
	onClose: () => void;
	onSubmit: (data: any) => void;
	onAccept: () => boolean;
	isOpen: boolean;
	component: ReactNode;
}
