import C2cDeliveryMethod from './c2cDeliveryMethod/c2cDeliveryMethod';
import C2cDeliveryMethodDetail from './c2cDeliveryMethodDetail/c2cDeliveryMethodDetail';
import C2cPromoCode from './c2cPromoCode/c2cPromoCode';
import ExtraService from './extraService/extraService';
import ProductClassification from './productClassification/productClassification';
import ProductSizeAndWeight from './productSizeAndWeight/productSizeAndWeight';
import SelectSize from './selectSize/selectSize';
import SelectWeight from './selectWeight/selectWeight';
import C2cPickedUpProduct from './c2cPickedUpProduct/c2cPickedUpProduct';
import C2cProductConfirm from './c2cProductConfirm/c2cProductConfirm';
import ProductCarousel from './productCarousel/productCarousel';
import ProductSearchInput from './productSearchInput/productSearchInput';
import ProductTypeSwitch from './productTypeSwitch/productTypeSwitch';
import ExtraServiceItem from './extraServiceItem/extraServiceItem';
import FeeRecordList from './feeRecordList/feeRecordList';

export {
	ProductSizeAndWeight,
	ProductClassification,
	C2cPromoCode,
	C2cDeliveryMethod,
	SelectSize,
	SelectWeight,
	ExtraService,
	C2cDeliveryMethodDetail,
	C2cPickedUpProduct,
	C2cProductConfirm,
	ProductCarousel,
	ProductSearchInput,
	ProductTypeSwitch,
	ExtraServiceItem,
	FeeRecordList,
};
