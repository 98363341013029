// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("Assets/icon/payment-method-background.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".vjc-radio-select-card.payment-method-select-card {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-repeat: no-repeat;\n  background-size: contain;\n  background-position: right;\n}\n.vjc-radio-select-card.payment-method-select-card .payment-method-select-card-description {\n  font-size: 0.8rem;\n  color: #828282;\n}\n\n.message-alert-payment-method {\n  margin: 0;\n  width: 100%;\n}", "",{"version":3,"sources":["webpack://./src/pages/OrderBill/SelectPaymentMethod/index.scss"],"names":[],"mappings":"AAIE;EACE,yDAAA;EACA,4BAAA;EACA,wBAAA;EACA,0BAAA;AAHJ;AAII;EACE,iBAAA;EACA,cAAA;AAFN;;AAOA;EACE,SAAA;EACA,WAAA;AAJF","sourcesContent":["@use '../../../variables' as v;\n@use '~Theme' as th;\n\n.#{v.$variable-prefix} {\n  &radio-select-card.payment-method-select-card {\n    background-image: url('~Assets/icon/payment-method-background.jpg');\n    background-repeat: no-repeat;\n    background-size: contain;\n    background-position: right;\n    .payment-method-select-card-description {\n      font-size: 0.8rem;\n      color: #828282;\n    }\n  }\n}\n\n.message-alert-payment-method {\n  margin: 0;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
